import Link from "next/link";
import { useEffect, useState } from "react";

const SearchCard = ({ card, closeModal }) => {
  const [link, setLink] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    switch (card.__typename) {
      case "Video":
        if (card.event.type === "course") setLink(`/curso/${card?.event?.course?.slug}/${card.slug}`);

        if (card.event.type === "event" || card.event.type === "webinar") setLink(`/evento/${card.event.slug}/${card.slug}`);

        setImage(`${card.thumbnail_url}?w=256&h=144`);
        break;

      case "Event":
        setLink(`/evento/${card.slug}`);
        const urlImg = `${process.env.IMAGES_URL}`;

        if (card.status === "vod") setImage(`${urlImg}/${card.poster_url}`);

        if (card.status === "live" || card.status === "idle" || card.status === "upcoming" || card.status === "finished")
          setImage(`${urlImg}/${card.upcoming_thumb_url}`);

        if (!card.upcoming_thumb_url && !card.poster_url) setImage(`${urlImg}/${card.banner_url}`);

        break;
    }
  }, [card]);

  return (
    <Link href={link} onClick={() => closeModal()}>
      <div className="w-full my-3 flex py-3 border-b-2 border-gray-500">
        <div className="w-1/4 mr-5">
          <img src={image} className="rounded-lg" />
        </div>
        <div className="w-3/4 flex flex-col">
          <div className="h-2/4">
            {card.__typename === "Video" && <p className="text-xs text-white mb-1">{card?.category?.name}</p>}

            <p className="text-sm sm:text-lg text-white font-semibold">{card.title}</p>
          </div>
          <div className="h-2/4 flex items-end pb-3">
            {card.__typename === "Video" && <p className="text-gray-400 text-xs mt-1">{card?.author}</p>}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default SearchCard;
