export const blackListedEmails = [
  "fzaventas@boehringer-ingelheim.com",
  "fzadeventas@boehringer-ingelheim.com",
  "Fzaventas@boehringer-ingelheim.com",
  "fezaventas@boehringer-ingelheim.com",
  "fza-ventas@boehringer-ingelheim.com",
  "fernando.ramirez@sinergis.com.mx",
];

export function groupAndCountViews(data, property) {
  const result = data.reduce((acc, item) => {
    const key = item[property];
    if (!acc[key]) {
      acc[key] = 0;
    }
    acc[key]++;
    return acc;
  }, {});

  const sortedResult = Object.entries(result)
    // @ts-ignore
    .sort(([, a], [, b]) => b - a)
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

  return sortedResult;
}

export function groupAndCountDiplomas(data, property) {
  const result = data.reduce((acc, item) => {
    let key;
    if (property === "country") {
      key = item.user.country.name;
    } else if (property === "specialty") {
      key = item.user.specialty.name;
    } else if (property === "event") {
      key = item.event?.title;
    } else {
      return acc; // If property is not valid, return the accumulator as is
    }

    acc[key] = (acc[key] || 0) + 1;
    return acc;
  }, {});

  const sortedResult = Object.entries(result)
    // @ts-ignore
    .sort((a, b) => b[1] - a[1])
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

  return sortedResult;
}

export function groupAndCountTraffic(data, property) {
  const counts = {};

  data.forEach((item) => {
    const key = item.user[property]?.name || "Unknown";
    counts[key] = (counts[key] || 0) + 1;
  });

  const sortedCounts = Object.entries(counts)
    // @ts-ignore
    .sort((a, b) => b[1] - a[1])
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});

  return sortedCounts;
  return {};
}

export function groupAndCountRegistered(data, groupBy) {
  const groupedData = {};

  // Iterate over each user in the data
  data.forEach((user) => {
    const key = user[groupBy].name;
    if (groupedData[key]) {
      groupedData[key]++;
    } else {
      groupedData[key] = 1;
    }
  });

  // @ts-ignore
  const sortedArray = Object.entries(groupedData).sort((a, b) => b[1] - a[1]);

  // Convert the sorted array back to an object
  const sortedGroupedData = Object.fromEntries(sortedArray);

  return sortedGroupedData;
}
