import { useState, useEffect } from "react";
import Link from "next/link";

export default function DropdownPanelMobile({
  children,
  session,
  showPrescribingLink,
}) {
  const [path, setPath] = useState("");

  useEffect(() => {
    setPath(window.location.pathname);
  });

  return (
    <div className="lg:hidden bg-navy absolute z-60 w-full">
      <div className="px-2 pt-2 pb-3 space-y-1">
        <Link
          href="/"
          className={`${
            path === "/"
              ? "bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
              : "text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
          }`}
        >
          Inicio
        </Link>

        <Link
          href="/mis-videos"
          className={`${
            path === "/mis-videos"
              ? "bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
              : "text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
          }`}
        >
          Mis videos
        </Link>
      </div>
      <div className="pt-4 pb-3 border-t border-gray-700">
        <div className="mt-3 px-2 space-y-1">
          <Link
            href="/editar-perfil"
            className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
          >
            Editar perfil
          </Link>

          <Link
            href="/solicitud-informacion-medica"
            className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
            role="menuitem"
          >
            Solicitud de información médica
          </Link>

          {(showPrescribingLink || Number(session.user.image.role) >= 5) && (
            <Link
              href="/ipp"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
              role="menuitem"
            >
              Información Para Prescribir
            </Link>
          )}
          <button
            data-it-button="rate-this-site"
            className="bg-blue-1400 text-white mx-4 my-2 text-xs px-4 py-2 rounded-md md:hidden"
          >
            Evalúe nuestro sitio
          </button>

          {children}
        </div>
      </div>
    </div>
  );
}
