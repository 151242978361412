import api from "@src/api";
import { curry, aCompose } from "@src/utilities/fp/functions";

import { parseCookies, setCookie } from "nookies";

const parseVisitCookie = () => {
  const cookies = parseCookies();
  const { visitCookie } = cookies;
  return visitCookie;
};

const createVisit = async (t, c) => {
  if (c) return;
  const v = await (new api.CreateVisit() as any).doMutation(null, t);
  return v;
};

const getExpirationTime = () => {
  const today = new Date(Date.now()).valueOf();

  const currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
  const tomorrow = new Date(`${year}-${month}-${day} 00:00:00`).valueOf();

  const expiration = (tomorrow - today) / 1000;

  return expiration;
};

const setVisitCookie = (v: { id: string }) => {
  if (!v) return;

  const expiration = getExpirationTime();

  setCookie(null, "visitCookie", v.id, {
    // maxAge: 60 * 60 * 12,
    maxAge: expiration,
    path: "/",
  });

  return true;
};

const curryCreateVisit = curry(createVisit);

export const handleVisitCookie = (token: string) => aCompose(setVisitCookie, curryCreateVisit(token), parseVisitCookie);
