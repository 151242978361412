declare var WSC: any;

export const createDiplomaEvent6 = ({ eVar23, eVar83 }) => {
  console.log("🧽 event6 data: ", eVar23, eVar83);
  
  const event6 = WSC.modules.instatag.createEvent("event6");
  event6.getEvar("eVar23").value = eVar23;
  event6.getEvar("eVar81").value = "Constancia";
  event6.getEvar("eVar82").value = "Constancia";
  event6.getEvar("eVar83").value = eVar83;
  event6.fire();
};
