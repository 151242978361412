const API_URL = "https://api-dev-biclick-0j1.graphcdn.app";
const API_TOKEN = process.env.DASHBOARD_API_TOKEN;

async function fetchAPI(query, { variables = null, preview = null } = {}) {
  const res = await fetch(API_URL + (preview ? "/preview" : ""), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${API_TOKEN}`,
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  const json = await res.json();
  // console.log('JSON ->', json, query)
  if (json.errors) {
    console.error("json errors ->", json.errors[0]);
    throw new Error("Failed to fetch API");
  }
  return json.data;
}

export async function getEventsList() {
  const data = await fetchAPI(`
  query {
    GetEventsList {
      id
      title
      slug
      start_date
    }
  } 
  `);
  return data.GetEventsList;
}

export async function getEvent(eventID) {
  const data = await fetchAPI(`
  query {
    getEvent( id: ${eventID} ) {
      id
      title
      start_date
      finish_date
      day
      duration
    }
  }
  `);
  return data.getEvent;
}

export async function getRegisteredTotal(eventID) {
  const data = await fetchAPI(`
  query {
    GetRegisteredTotal(
      event_id: ${eventID}
    )
  }
  `);
  return data.GetRegisteredTotal;
}

export async function getAttendedTotal(eventID) {
  const data = await fetchAPI(`
  query {
    GetAttendedTotal(
      event_id: ${eventID}
    )
  }
  `);
  return data.GetAttendedTotal;
}

export async function getTotalDownloadedDiplomas(eventID) {
  const data = await fetchAPI(`
  query {
    GetTotalDownloadedDiplomas(event_id: ${eventID})
  }
  `);
  return data.GetTotalDownloadedDiplomas;
}

export async function getPlayAverage(eventID, totalUsers) {
  const data = await fetchAPI(`
  query {
    GetPlayAverage( event_id: ${eventID} total_users: ${totalUsers} )
    }
  `);
  return data.GetPlayAverage;
}

export async function getRetention(eventID) {
  const data = await fetchAPI(`
  query {
    GetRetention( event_id: ${eventID} )
  }
  `);
  return data.GetRetention;
}

export async function getTotalMessages(eventID) {
  const data = await fetchAPI(`
  query {
      GetTotalMessages(event_id: ${eventID})
  }
  `);
  return data.GetTotalMessages;
}

export async function getMaxConnected(eventID) {
  const data = await fetchAPI(`
  query {
    GetMaxConnected(event_id: ${eventID})
  }
  `);
  return data.GetMaxConnected;
}

export async function getAttendedPercentage(eventID, attendedTotal) {
  const data = await fetchAPI(`
  query {
    GetAttendedPercentage(
    event_id: ${eventID}
    attended_total: ${attendedTotal}
    )
    }
  `);
  return data.GetAttendedPercentage;
}

export async function getStatisticAgent(eventId, value, day = null) {
  const data = await fetchAPI(`
  query {
    GetStatisticAgent(
     event_id: ${eventId}
     value: "${value}"
     day: ${day}
   ) {
     value
     total
   }
 }
  `);
  return data.GetStatisticAgent;
}

export async function getStatisticUser(eventID, value, field, day = null) {
  const data = await fetchAPI(`
  query {
    GetStatisticUser(
       event_id: ${eventID}
      value: "${value}"
      field: "${field}"
      day: ${day}
    ) {
      value
      total
    }
  }
  `);
  return data.GetStatisticUser;
}

export async function getRealTime(day, eventID) {
  const data = await fetchAPI(`
  query {
    GetRealTime(
      day: ${day}
      event_id: ${eventID}
    ) {
      id
      at_minute
      count
      created_at
    }
  }
  `);
  return data.GetRealTime;
}

export async function getDashboardUsers(eventID) {
  const data = await fetchAPI(`
  query {
        GetDashboardUsers(
          event_id: ${eventID}
        ) {
          veeva_id
          nombre_completo
          cedula
          telefono
          correo
          pais
          estado
          especialidad
          lugar_de_trabajo
          institucion_pertenece
          otra_institucion
          unidad_medica
          jurisdiccion_sanitaria
          tiempo_online
          tiempo_online_porcentaje
          constancias
          nombre_video
          dia
          territory_code
          bi_veeva_id
          specialty_code
          }
        }
  `);
  return data.GetDashboardUsers;
}

export async function eventExists(slug) {
  const data = await fetchAPI(`
  query {
    EventExists(
      slug: "${slug}"
    ) {
      id
    }
  }
  `);
  return data.EventExists;
}

export async function getGraphData(date, toDate, ignoreOtherSpecialties = false) {
  const data = await fetchAPI(`
    query {
      graphData: getGraphData(date: "${date}", ${
    toDate ? 'toDate: "' + toDate + '"' : "toDate: null"
  }, ignoreOtherSpecialties: ${ignoreOtherSpecialties} ) {    
        view_id
        view_created_at
        formatted_view_created_at_by_day
        user_id
        user_name
        user_email
        user_specialty
        new_user_specialty
        user_country
        event_id
        event_date
        event_title
        event_slug
        event_category
        event_brands {
          id
          name
        }
        event_brands_string
      }
    }`);
  return data.graphData;
}
